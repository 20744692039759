<template>
  <b-form class="pb-5">
    <b-row>
      <b-col>
        <h1>Attachment {{ attachment.id }}</h1>
        <b-form-checkbox
          v-model="attachment.active"
          name="active"
          class="content-switch"
          switch
          ><b>Active</b></b-form-checkbox
        >
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          :disabled="saveDisabled"
          class="mb-5 save-button"
          @click="save()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs class="mt-3">
      <b-tab v-for="language in languages" :key="language">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <!-- Title / Description -->
        <!-- Choose Type -->
        <b-form-group class="mb-2 mr-sm-2 mb-sm-0" label="Type">
          <b-form-select
            name="type"
            v-model="attachment.type[language]"
            v-validate="'required'"
            :options="types"
          >
          </b-form-select>
          <div v-if="submitted && errors.has('type')" class="alert-danger">
            {{ errors.first("type") }}
          </div>
        </b-form-group>

        <!-- Insert attachment -->
        <b-form-group name="url" label="Url">
          <b-form-input
            name="url"
            v-validate="'required'"
            v-model="attachment.url[language]"
          ></b-form-input>
          <div v-if="submitted && errors.has('url')" class="alert-danger">
            {{ errors.first("url") }}
          </div>
        </b-form-group>

        <!-- THUMBNAIL -->
        <b-form-group label-size="lg" label="Thumbnail">
          <ImageUpload :url="attachment.thumbnail[language]" ref="image" @updateImage="updateImage(language)"/>
        </b-form-group>
        <b-form-group label="Title">
          <b-form-input
            name="title"
            v-validate="'required'"
            v-model="attachment.title[language]"
          ></b-form-input>
          <div v-if="submitted && errors.has('title')" class="alert-danger">
            {{ errors.first("title") }}
          </div>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            v-model="attachment.description[language]"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Category">
          <b-form-input v-model="attachment.category[language]"></b-form-input>
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue"

export default {
  components: {
    ImageUpload
  },
  data() {
    return {
      types: [
        { value: "document", text: "Document" },
        { value: "youtube", text: "Youtube" },
        { value: "other", text: "Other" },
      ],
      selectedLanguage: null,
      saveDisabled: true,
      saveMessage: "",
      submitted: false,
    };
  },
  async created() {
    this.selectedLanguage = this.languages[0];
    if (this.$route.query.id) {
      await this.doFetch();
    } else {
      this.$store.commit("Attachments/EMPTY_CURRENT");
    }
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    attachment: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Attachments/fetchById", this.$route.query.id);
      this.isLoading = false;
    },
    async save() {
      this.submitted = true;
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          return;
        }
        this.isLoading = true;

        this.$refs.image.uploadImage();
        await this.$store.dispatch("Attachments/update", this.attachment);
        this.$root.$bvToast.toast("Page settings saved!", {
          variant: "success",
        });
        this.saveDisabled = true;
        this.submitted = false;
        this.saveMessage = "";
        this.isLoading = false;
      });
    },
    updateImage(language, newUrl) {
      this.attachment.thumbnail[language] = newUrl
    },
  },
  computed: {
    attachment() {
      return this.$store.state.Attachments.attachment;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
